import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DataContractProperty } from '@sgmo/shared-components';
import { getConfig } from '@/config/config';
import { commonPrepareHeaders } from './apiUtils';

export const bookingApi = createApi({
  reducerPath: 'bookingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig()?.api?.endpoint ?? ''}booking/`,
    prepareHeaders: commonPrepareHeaders,
  }),
  tagTypes: ['Instruments', 'InstrumentDescriptions'],
  endpoints: (builder) => ({
    getInstrumentsList: builder.query<string[], void>({
      query: () => ({
        url: 'instruments',
        method: 'GET',
      }),
      transformResponse: (baseQueryReturnValue: { instruments: string[] }, _meta, _arg) =>
        baseQueryReturnValue.instruments,
      providesTags: ['Instruments'],
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
    getInstrumentDescription: builder.query<DataContractProperty, string>({
      query: (instrumentName) => ({
        url: `instruments/${instrumentName}/description`,
        method: 'GET',
      }),
      providesTags: (_result, _error, instrumentName) => [
        { type: 'InstrumentDescriptions', instrumentName },
      ],
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
    getDealInstrumentDescription: builder.query<DataContractProperty, string>({
      query: (dealId) => ({
        url: `deals/${dealId}/instrument/description`,
        method: 'GET',
      }),
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
    bookDeal: builder.mutation<
      BookingResult,
      { sessionId: string; version: number; actionName: string; dealId: string; etag: string }
    >({
      query: ({ sessionId, version, actionName, dealId, etag }) => ({
        url: `deals/trade-sessions/${sessionId}/versions/${version}`,
        headers: { etag },
        method: 'POST',
        params: [
          ['actionName', actionName],
          ['dealId', dealId],
        ],
      }),
    }),
    getTradeLifecycleHistory: builder.query<TradeHistory[], string>({
      query: (tradeReference) => ({
        url: `deals/${tradeReference}/history`,
        method: 'GET',
      }),
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
  }),
});

export const {
  useLazyGetInstrumentsListQuery,
  useLazyGetInstrumentDescriptionQuery,
  useLazyGetDealInstrumentDescriptionQuery,
  useBookDealMutation,
  useLazyGetTradeLifecycleHistoryQuery,
} = bookingApi;
